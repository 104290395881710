import React from 'react';
import withStyles from 'react-jss';
import { style } from './style';

import KoreanFlag from '../../lib/image/korean-flag.png';
import VietnamFlag from '../../lib/image/vietnam-flag.png';

class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderWidth: 0,
      slideNumber: 1,
    };
    this.autoScrollSlide = null;
  }

  componentDidMount() {
    let sliderWidth = this.slider.offsetWidth;
    this.setState({ sliderWidth });
    this.slide1.style.width = `${sliderWidth}px`;
    this.slide2.style.width = `${sliderWidth}px`;
    this.slide3.style.width = `${sliderWidth}px`;
    this.slideWrapper.style.width = `${sliderWidth * 3}px`;

    this.autoScrollSlide = setInterval(() => {
      // this.goToNextSlide();

      let { slideNumber } = this.state;
      let newSlideNumber = slideNumber + 1;
      if (newSlideNumber > 3) newSlideNumber = 1; // go back slider 1
      this.goToSlide({
        newSlideNumber,
        isManual: false,
      });
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.autoScrollSlide);
  }

  // goToNextSlide = () => {
  //   const { sliderWidth } = this.state;
  //   this.slider.scrollBy({
  //     top: 0,
  //     left: sliderWidth,
  //     behavior: 'smooth'
  //   });
  // }

  // goToPrevSlide = () => {
  //   const { sliderWidth } = this.state;
  //   this.slider.scrollBy({
  //     top: 0,
  //     left: -sliderWidth,
  //     behavior: 'smooth'
  //   });
  // }

  goToSlide = ({
    newSlideNumber,
    isManual,
  }) => {
    const { sliderWidth, slideNumber } = this.state;
    this.slider.scrollBy({
      top: 0,
      left: (newSlideNumber - slideNumber) * sliderWidth,
      behavior: 'smooth'
    });
    this.setState({ slideNumber: newSlideNumber });

    if (isManual === true) {
      clearInterval(this.autoScrollSlide);
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className="container">
          <div className={classes.slider} ref={el => this.slider = el}>
            <div ref={el => this.slideWrapper = el} style={{ display: 'flex', }}>
              <div ref={el => this.slide1 = el} className={classes.slide}>
                <div className="row">
                  <div className="col-6 centerize" >
                    <img
                      src={KoreanFlag}
                      className={classes.koreanFlag}
                    ></img>
                  </div>
                  <div className={[
                    'col-6',
                    classes.slideContent,
                  ].join(' ')}>
                    <p className={classes.title}>Lớp tiếng Hàn trải nghiệm</p>
                    <ul className={classes.description}>
                      <li className={classes.price}>Chỉ 200,000 đ</li>
                      <li>2 tuần x 3 buổi x 1 tiếng</li>
                      <li>Học tất cả nguyên âm phụ âm,
    cách ghép âm, phát âm</li>
                      <li>Thực hành giao tiếp các mẫu câu cơ bản</li>
                      <li>Liên tục khai giảng từ 14 / 03 / 2022</li>
                      <li>Nhiều khung giờ học để lựa chọn</li>
                    </ul>
                    <p className={classes.subTitle}>Lớp online <span className={classes.price}>học phí 150,000 đ</span></p>
                  </div>
                </div>
              </div>
              <div ref={el => this.slide2 = el} className={classes.slide} >
                <div className="row">
                  <div className="col-6 centerize" >
                    <img
                      src={VietnamFlag}
                      className={classes.vietnamFlag}
                    ></img>
                  </div>
                  <div className={[
                    'col-6',
                    classes.slideContent,
                  ].join(' ')}>
                    <p className={classes.title}>Vietnamese language:</p>
                    <ul className={classes.description}>
                      <li>Free trial class ( 2 hours )</li>
                      <li>Only <span className={classes.price}>$9/ hour</span> apply for all courses from 2022/03/14 - 2022/04/30 ( Grand opening)</li>
                      <li>100% Vietnamese teaching, explaining by Vietnamese ( Student will be explained again by English if they couldn’t understand  the lesson)</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div ref={el => this.slide3 = el} className={classes.slide} >
                <div className="row">
                  <div className="col-6 centerize" >
                    <img
                      src={VietnamFlag}
                      className={classes.vietnamFlag}
                    ></img>
                  </div>
                  <div className={[
                    'col-6',
                    classes.slideContent,
                  ].join(' ')}>
                    <p className={classes.title}>베트남어:</p>
                    <ul className={classes.description}>
                      <li>시범 수업: 무료 (2시간)</li>
                      <li>2022/3/14-2022/04/30 학비: 1 시간에</li>
                      <li className={classes.price}>9달라</li>
                      <li>풀타임 베트남어로 설명함, 가르침 (수업한 후에는 학생이 이해가 안 되면 다시 한국어로 설명함)</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="centerize">
            {/* <button ref={el => this.prevBtn = el} style={{ margin: 20 }} onClick={this.goToPrevSlide}>prev</button>
            <button ref={el => this.nextBtn = el} style={{ margin: 20 }} onClick={this.goToNextSlide}>next</button> */}
            <button
              ref={el => this.dot1 = el}
              style={{ margin: 20 }}
              onClick={this.goToSlide.bind(this, {
                newSlideNumber: 1,
                isManual: true,
              })}
              className={classes.dot}
            ></button>
            <button
              ref={el => this.dot2 = el}
              style={{ margin: 20 }}
              onClick={this.goToSlide.bind(this, {
                newSlideNumber: 2,
                isManual: true,
              })}
              className={classes.dot}
            ></button>
            <button
              ref={el => this.dot3 = el}
              style={{ margin: 20 }}
              onClick={this.goToSlide.bind(this, {
                newSlideNumber: 3,
                isManual: true,
              })}
              className={classes.dot}
            ></button>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(style)(Slider);
