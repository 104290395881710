import HoaVanPattern from '../../lib/image/hoavan-pattern.jpeg';

const style = {
  footer: {
    background: `url(${HoaVanPattern})`,
  },
  footerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    color: 'var(--skin)',
    padding: '30px 0',
    '@media (max-width: 575px)': {
      '&': {
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px 0',
      }
    }
  },
  facebookIcon: {
    width: 30,
    marginRight: 6,
  },
  facebookURL: {
    color: 'var(--skin)',
  },
  address: {
    '@media (max-width: 575px)': {
      '&': {
        textAlign: 'center',
      }
    }
  }
};

export { style };
