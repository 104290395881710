import { createUseStyles } from 'react-jss';
import hoavanPattern from './lib/image/hoavan-pattern.jpeg';
import homeIcon from './lib/icons/home.svg';

import { Header } from './components/header/header';
import Slider from './components/slider/slider';
import Footer from './components/footer/footer';

const useStyles = createUseStyles({
  wrapper: {
    // width: '100vw',
    // height: '100vh',
    background: 'var(--skin)',
    // backgroundImage: `url(${hoavanPattern})`,
  },
});

function App() {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Header />

      <Slider />

      <Footer />
    </div>
  );
}

export default App;
