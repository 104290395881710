import React from 'react';
import { createUseStyles } from 'react-jss';
import { style } from './style';
import logo from '../../lib/image/logo.png';
import dayDeo from '../../lib/image/day-deo.png';
import homeIcon from '../../lib/icons/home.svg';
import emailIcon from '../../lib/icons/email.svg';
import phoneIcon from '../../lib/icons/phone.svg';

const useStyles = createUseStyles(style);

function Header() {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <div className="container">
        <div className={classes.logoWrapper}>
          <img src={logo} className={classes.logo} />
          <p className={classes.hanah}>Trung Tâm Hàn Ngữ HANAH</p>
          <img src={dayDeo} className={classes.daydeo} />
        </div>

        <div className={[
          'display-flex', 'justify-content-space-around'
        ].join(' ')} style={{ paddingBottom: 12 }}>
          <div className={[
            'display-flex'
          ].join(' ')}>
            <img src={phoneIcon} className={classes.phoneIcon} />
            <p className={classes.phoneText}>094 864 3867</p>
          </div>
          <div className={[
            'display-flex'
          ].join(' ')}>
            <img src={emailIcon} className={classes.emailIcon} />
            <p className={classes.emailText}>
              <a href="mailto:support@hanah.edu.vn">support@hanah.edu.vn</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Header };
