const style = {
  slider: {
    overflow: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  slide: {
    height: 570,
    '@media (max-width: 575px)': {
      '&': {
        height: 590,
      }
    }
  },
  koreanFlag: {
    width: '80%',
    '@media (max-width: 575px)': {
      '&': {
        width: '60%',
      }
    }
  },
  vietnamFlag: {
    width: '70%',
    '@media (max-width: 575px)': {
      '&': {
        width: '60%',
      }
    }
  },
  slideContent: {
    '@media (max-width: 575px)': {
      '&': {
        padding: '0 10px',
      }
    }
  },
  title: {
    fontSize: 40,
    lineHeight: 3,
    color: 'var(--dark-blue)',
    '@media (max-width: 575px)': {
      '&': {
        fontSize: 26,
        lineHeight: 1.8,
      }
    }
  },
  description: {
    fontSize: 26,
    lineHeight: 2,
    '@media (max-width: 575px)': {
      '&': {
        fontSize: 20,
        lineHeight: 1.8,
      }
    }
  },
  price: {
    fontSize: 30,
    color: '#dd4b3c',
    '@media (max-width: 575px)': {
      '&': {
        fontSize: 26,
      }
    }
  },
  subTitle: {
    fontSize: 34,
    lineHeight: 2,
    color: '#234350',
    '@media (max-width: 575px)': {
      '&': {
        fontSize: 26,
      }
    }
  },
  dot: {
    margin: 20,
    width: 10,
    height: 10,
    borderRadius: 10,
    background: 'var(--dark-blue)',
  },
};

export { style };
