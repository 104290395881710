const style = {
  header: {
    background: 'var(--skin)',
  },
  logoWrapper: {
    position: 'relative',
    height: 142,
    '@media (max-width: 575px)': {
      '&': {
        height: 110,
      }
    }
  },
  logo: {
    width: 80,
    height: 80,
    position: 'absolute',
    top: 40,
    '@media (max-width: 575px)': {
      '&': {
        left: 20,
        top: 20,
        width: 60,
        height: 60,
      }
    }
  },
  hanah: {
    color: 'var(--dark-blue)',
    fontSize: 50,
    position: 'absolute',
    top: 40,
    left: 234,
    '@media (max-width: 575px)': {
      '&': {
        top: 16,
        left: 106,
        fontSize: 24,
      }
    }
  },
  daydeo: {
    width: 200,
    height: 200,
    position: 'absolute',
    right: 20,
    top: -28,
    '@media (max-width: 575px)': {
      '&': {
        top: -28,
        right: -32,
        width: 140,
        height: 140,
      }
    }
  },
  phoneIcon: {
    width: 26,
  },
  phoneText: {
    color: 'var(--dark-blue)',
    marginLeft: 6,
  },
  emailIcon: {
    width: 26,
  },
  emailText: {
    marginLeft: 6,
    '& a': {
      color: 'var(--dark-blue)',
      textDecoration: 'none',
    }
  },
};

export { style };
