import { Component } from 'react';
import withStyles from 'react-jss';
import { style } from './style';
import FacebookIcon from '../../lib/icons/facebook.png';

class Footer extends Component {

  render() {
    const { classes } = this.props;
    const facebookURL = 'https://www.facebook.com/hanah.edu.vn';

    return (
      <div className={classes.footer}>
        <div className="container">
          <div className={classes.footerContent}>
            <p>@ 2022</p>
            <div className="centerize">
              <img src={FacebookIcon} className={classes.facebookIcon} />
              <p><a target="__blank" className={classes.facebookURL} href={facebookURL}>@hanah.edu.vn</a></p>
            </div>
            <p className={classes.address}>Địa chỉ/ address/주소: 28 đường số 1, KDC Nam Long, Tân Thuận Đông, Quận 7</p>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(style)(Footer);
